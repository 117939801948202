import React from 'react';
import '../styles/index.css';
import PageLayout from "../components/PageLayout";
import { graphql } from "gatsby";
import { CareersQuery } from '../../graphql-types'
import OpenPositions from '../components/careers/OpenPositions';
import { Hidden } from '@material-ui/core';

interface Props {
  data: CareersQuery;
}

const Careers = ({data}: Props) => {
  return (
    <>
      <Hidden xsDown>
        <PageLayout title='Careers | FS Woodworks'>
          <OpenPositions positions={data.allSanityCareers.nodes[0].openPositions} />
        </PageLayout>
      </Hidden>
      <Hidden smUp>
        <PageLayout title='Careers | FS Woodworks' darkNavBar>
          <OpenPositions positions={data.allSanityCareers.nodes[0].openPositions} />
        </PageLayout>
      </Hidden>
    </>
  );
}

export const query = graphql`
  query careers {
    allSanityCareers {
      nodes {
        id
        openPositions {
          name
        }
      }
    }
  }
`;

export default Careers;
