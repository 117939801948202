import { SanityOpenPosition } from "../../../graphql-types";
import { SanityArray } from "../../types";
import styles from '../../styles/careers/OpenPositions.module.css'
import React from "react";
import ArrowButton from "../ArrowButton";
import { Image } from "rebass";
import slugify from 'slugify'
import { Hidden } from "@material-ui/core";

export interface Props {
  positions?: SanityArray<SanityOpenPosition>;
}

const OpenPositions = ({positions}: Props) => {
  return (
    <section className={ styles.container }>
      <h1 className={ styles.header }>Careers</h1>
        <div className={styles.positionsContainer}>
          <ol className={styles.positionList}>
          {
            positions?.map((position, i) => 
                <li className={styles.position} key={position?.name || i}>
                  <p className={styles.positionNumber}>0{i + 1}</p>
                  <p className={styles.positionName}>{position?.name}</p>
                  <ArrowButton to={`/careers/${slugify(position?.name || '', {lower: true})}`} dark>Apply now</ArrowButton>
                </li>
            )
          }
          </ol>
          <div className={styles.imageContainer}>
            <Hidden xsDown>
              <Image
                src='/images/careers.png'
                className={styles.image}
              />
            </Hidden>
            <Hidden smUp>
              <Image
                src='/images/careers-small.png'
                className={styles.image}
              />
            </Hidden>
            <Image
              src='/images/careers-stamp.png'
              className={styles.stamp}
            />
          </div>
        </div>
    </section>
  )
}

export default OpenPositions